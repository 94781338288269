import cx from 'classnames'
import { FastField, Form, withFormik } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { getCookie, getReferrer, getUTM } from '../../helpers'
import { Button2 } from '../button2'
import jobFormStyles from './terms-form.module.css'
// import mainFormStyles from "./main-form2.module.css"
import styles from './form2.module.css'

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, 'phone', function() {
  return this.test('phone', 'Telefonní číslo musí obsahovat 9 znaků', value =>
    rePhoneNumber.test(value)
  )
})

export const TermsFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  btnType = 'secondary',
  btnText = 'Objednat',
  form_name = 'add-review_new',
  setFieldValue,
}) => {
  const [checkTerms2, setCheckTerms2] = useState(false)
  const { Trans, t } = useTranslation()
  return (
    <Form
      name={form_name}
      method="post"
      className={cx(jobFormStyles.termsForm)}
    >
      <div className={cx(styles.inputField, jobFormStyles.nameInput)}>
        <FastField
          component="input"
          type="text"
          name="name"
          className={styles.input}
          placeholder={t('forms.complaintForm.nameInput')}
        />
        {touched.name && errors.name && (
          <span className={styles.error}>{errors.name}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.phoneInput)}>
        <FastField
          component="input"
          type="tel"
          name="phone"
          className={cx(styles.input)}
          placeholder={t('forms.complaintForm.phoneInput')}
        />
        {touched.phone && errors.phone && (
          <span className={cx(styles.error)}>{errors.phone}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.emailInput)}>
        <FastField
          component="input"
          type="email"
          name="email"
          className={styles.input}
          placeholder={t('forms.complaintForm.emailInput')}
        />
        {touched.email && errors.email && (
          <span className={styles.error}>{errors.email}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.fakturaInput)}>
        <FastField
          component="input"
          type="text"
          name="faktura"
          className={styles.input}
          placeholder={t('forms.complaintForm.invoiceInput')}
        />
        {touched.faktura && errors.faktura && (
          <span className={styles.error}>{errors.faktura}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.commentInput)}>
        <FastField
          component="textarea"
          type="text"
          name="comment"
          className={styles.input}
          placeholder={t('forms.complaintForm.causeComplaintInput')}
        />
        {touched.comment && errors.comment && (
          <span className={styles.error}>{errors.comment}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.comment2Input)}>
        <FastField
          component="textarea"
          type="text"
          name="comment2"
          className={styles.input}
          placeholder={t('forms.complaintForm.descriptionInput')}
        />
        {touched.comment2 && errors.comment2 && (
          <span className={styles.error}>{errors.comment2}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.termsInput)}>
        <input
          id="checkTerms2"
          type="checkbox"
          name="checkTerms2"
          checked={checkTerms2}
          onChange={e => {
            setCheckTerms2(e.target.checked)
          }}
        />
        <label htmlFor="checkTerms2">
          {t('forms.mainFormCheckTerms3Label')}
          {t('forms.mainFormCheckTerms4Label')}
        </label>
      </div>
      {/* <div
        className={cx(
          styles.inputField,
          mainFormStyles.checkTerms,
          jobFormStyles.termsInput
        )}
      >
        <FastField type="checkbox" id="checkTerms2" name="checkTerms2" />
        <label htmlFor="checkTerms2">
          <Trans i18nKey="forms.mainFormCheckTerms3Label">
            Souhlasím se zpracováním{" "}
            <button
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={show}
            >
              osobních údajů
            </button>
          </Trans>
        </label>
        <ErrorMessage
          component="div"
          className={mainFormStyles.inputError}
          name="checkTerms2"
        />
      </div> */}
      <div className={cx(jobFormStyles.submitButton)}>
        <Button2
          color="secondary"
          buttonType="submit"
          disabled={isSubmitting || !checkTerms2}
        >
          {t('forms.complaintForm.btnText')}
        </Button2>
      </div>
    </Form>
  )
}

export const TermsForm = withFormik({
  mapPropsToValues: props => ({
    name: '',
    email: '',
    phone: '',
    faktura: '',
    comment: '',
    comment2: '',
    referrer: '',
    ga: '',
    success: false,
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string()
        .min(2)
        .required(),
      email: Yup.string().required(),
      phone: Yup.string().required(),
      faktura: Yup.string().required(),
      comment: Yup.string()
        .min(2)
        .required(),
      comment2: Yup.string()
        .min(2)
        .required(),
    }),
  handleSubmit: async (
    { name, email, phone, faktura, comment, comment2 },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes('/en')

      const data = {
        form_name: 'Reklamační formulář',
        name: name,
        email: email,
        phone: phone,
        faktura: faktura,
        comment: comment,
        comment2: comment2,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie('roistat_visit'),
        ga: getCookie('_ga'),
      }
      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/complaint`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)
        setTimeout(() => {
          resetForm()
          window.dataLayer && window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn ? "/en/thank-you-add-review" : "/thank-you-add-review",
            pageType: "Purchase",
          })
        }, 300)
        window.location.href = isEn
          ? "/en/thank-you"
          : "/dekovacka-testdrive"
      }
    
    } catch (err) {
      setSubmitting(false)
      setFieldValue('success', false)
      alert('Something went wrong, please try again!')
    }
  },
})(TermsFormLayout)
